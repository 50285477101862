import commonHelper from '../../../utility/common.helper.utility';
import ModulesList from '@/app/components/admin/responsibility/searchModule';
import DatePicker from 'vue2-datepicker';
import Currency from '../../admin/currency';
import appStrings from '@/app/utility/string.utility';
import URL_UTILITY from '../../../utility/url.utility';
import openKmAuthentication from '../../onlineApproval/internalWorkFlow/openKmAuthentication';
import party from '@/app/components/generalLedger/party';
export default {
  name: 'payableinvocies',
  watch: {
    currentPage: function() {
      this.getUploadedDocDtl();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getUploadedDocDtl();
    }
  },
  components: {
    openKmAuthentication,
    ModulesList,
    DatePicker,
    Currency,
    party
  },

  data() {
    return {
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      loader: false,
      showAlert: false,
      isSuccess: false,
      openCurrencyModal: false,
      showValueSetModal: false,
      responseMsg: '',
      selectedModule: {
        name: null,
        id: null
      },
      vendor: {
        name: null,
        id: null
      },
      vendorSite: {
        name: null,
        id: null
      },
      selectedCustomer: {
        name: null,
        customerId: null,
        siteName: null,
        siteId: null
      },
      vendorInvoiceNumber: '',
      legalEntity: {
        text: null,
        value: null
      },
      appStatus: {
        text: null,
        value: null
      },
      payStatus: {
        text: null,
        value: null
      },
      currency: {
        name: '',
        id: null
      },
      apInvoiceId: null,
      invoiceDetails: null,
      showPayableInvoicesModal: false,
      showRejectInvoiceModal: false,
      showHideModule: false,
      showApproveBtn: false,
      showPartyModal: false,
      showCustomerSiteModal: false,
      isAdvanceSearchEnable: false,
      showOpenKmModal: false,
      openkmAuthToken: null,
      showDownloadModal: false,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      vendorInvoiceDate: [],
      gridDataSite: [],
      gridFieldsSite: [
        {
          key: 'site_name'
        },
        {
          key: 'site_type'
        },
        {
          key: 'bill_to_address',
          label: 'Site Address'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'gstn',
          label: 'GSTN'
        }
      ],
      payableInvoicesData: [],
      payableInvoicesFields: [
        {
          key: 'select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'original_file_name',
          label: 'View Document'
        },
        {
          key: 'module'
        },
        {
          key: 'vendor'
        },
        {
          key: 'vendor_site'
        },
        {
          key: 'vendor_tax_invoice_num',
          label: 'Vendor Invoice No.'
        },
        {
          key: 'vendor_tax_invoice_date',
          label: 'Vendor Invoice Date'
        },
        {
          key: 'tax_type_meaning',
          label: 'Tax Type'
        },
        {
          key: 'tax_rate'
        },
        {
          key: 'taxable_amount'
        },
        {
          key: 'cgst_amount',
          label: 'CGST Amount'
        },
        {
          key: 'sgst_amount',
          label: 'SGST Amount'
        },
        {
          key: 'igst_amount',
          label: 'IGST Amount'
        },
        {
          key: 'invoice_amount'
        },
        {
          key: 'pp_approval_status',
          label: 'PP Approved Status'
        },
        {
          key: 'pp_approval_date',
          label: 'PP Approved Date'
        },
        {
          key: 'pp_approved_by',
          label: 'PP Approved By'
        },
        {
          key: 'posting_status_meaning',
          label: 'PP Posting Status'
        },
        {
          key: 'rejected_reason'
        },
        {
          key: 'le_full_name',
          label: 'Legal Entity'
        },
        {
          key: 'party_type'
        },
        {
          key: 'invoice_date'
        },
        {
          key: 'invoice_num'
        },
        {
          key: 'amount_paid',
          class: 'text-center'
        },
        {
          key: 'gross_amount'
        },
        {
          key: 'currency'
        },
        {
          key: 'invoice_source'
        },
        {
          key: 'approval_status_meaning',
          label: 'Approval Status'
        },
        {
          key: 'payment_status_meaning',
          label: 'Payment Status'
        },
        {
          key: 'submission_date'
        },
        {
          key: 'tax_invoice_date'
        },
        {
          key: 'tax_invoice_no'
        }
      ],
      downloadItems: [],
      downloadFields: [
        {
          key: 'original_file_name',
          label: 'File Name'
        },
        {
          key: 'doc_version',
          label: 'Version'
        },
        {
          key: 'file_size',
          label: 'Size'
        }
      ],
      reject: null,
      isViewMore: false
    };
  },
  mounted() {
    this.eventBus.$on('getCurrency', getCurrency => {
      this.currency.name = getCurrency.currency;
      this.currency.id = getCurrency.currency_id;
      this.openCurrencyModal = false;
    });
  },
  methods: {
    searchGridData() {
      this.getPayableInvoiceList();
      this.payableInvoicesData = [];
      this.showApproveBtn = false;
      this.isViewMore = false;
    },
    getPayableInvoiceList() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        module_id: this.selectedModule.id,
        vendor_id: this.selectedCustomer.customerId,
        vendor_site_id: this.selectedCustomer.siteId,
        vendor_tax_invoice_no: this.vendorInvoiceNumber,
        le_id: this.legalEntity.value,
        approval_status: this.appStatus.value,
        payment_status_flag: this.payStatus.value,
        currency_id: this.currency.id,
        vendor_tax_invoice_from_date: commonHelper.formattedDate(
          this.vendorInvoiceDate[0]
        ),
        vendor_tax_invoice_to_date: commonHelper.formattedDate(
          this.vendorInvoiceDate[1]
        )
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/getPayableInvoiceList', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.payableInvoicesData = response.data.data.page;
            this.payableInvoicesData.map(data => {
              data.original_file_name = 'Details';
              return data;
            });
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedInvoice(item) {
      this.invoiceDetails = item;
      if (item.pp_approval_status === 'DRAFT') {
        this.showApproveBtn = true;
      } else {
        this.showApproveBtn = false;
      }
    },
    hideModuleModal(flag) {
      this.showHideModule = flag;
    },
    selectedModuleData(module) {
      this.selectedModule.name = module.module_name;
      this.selectedModule.id = module.module_id;
      this.showHideModule = false;
    },
    openCloseCurrency(flag) {
      this.$store.dispatch('party/setUnsetPartyId', 1);
      this.openCurrencyModal = flag;
    },
    showHidePartyModal(flag) {
      this.showPartyModal = flag;
      setTimeout(() => {
        this.eventBus.$emit('userType', 'VEND');
      }, 0);
    },
    getSelectedPartyCustomer(item) {
      this.selectedCustomer.name = item.party_name;
      this.showPartyModal = false;
      this.getVendorIdByPartyId(item.party_id);
    },
    getVendorIdByPartyId(partyId) {
      this.$store
        .dispatch('leaseSchedule/getVendorIdByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            this.selectedCustomer.customerId = response.data.data;
            this.getVendorSites();
          }
        });
    },
    getVendorSites() {
      this.$store
        .dispatch(
          'leaseSchedule/getVendorSiteByVendorId',
          this.selectedCustomer.customerId
        )
        .then(response => {
          this.gridDataSite = response.data.data.vendor_sites;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideCustomerSiteModal(flag) {
      if (this.selectedCustomer.name) {
        this.showCustomerSiteModal = flag;
      } else {
        alert('Please Select Vendor First');
      }
    },
    selectedSite(item) {
      this.selectedCustomer.siteName = item.site_name;
      this.selectedCustomer.siteId = item.customer_site_id;
      this.showCustomerSiteModal = false;
    },
    clearFilters() {
      this.selectedModule = {
        name: null,
        id: null
      };
      this.vendor = {
        name: null,
        id: null
      };
      this.vendorSite = {
        name: null,
        id: null
      };
      this.vendorInvoiceNumber = '';
      this.legalEntity = {
        name: null,
        id: null
      };
      this.appStatus = {
        name: null,
        id: null
      };
      this.payStatus = {
        name: null,
        id: null
      };
      this.currency = {
        name: '',
        id: null
      };
      this.selectedCustomer.customerId = null;
      this.selectedCustomer.siteId = null;
      this.selectedCustomer.name = null;
      this.selectedCustomer.siteName = null;
      this.showApproveBtn = false;
      this.payableInvoicesData = [];
      this.vendorInvoiceDate = [];
      this.isViewMore = false;
      this.totalRows = null;
      this.currentPage = 1;
      this.responseMsg = '';
    },
    showApproveInvoice() {
      const payload = {
        ap_invoice_id: this.invoiceDetails.ap_invoice_id
      };
      this.loader = true;
      this.$store
        .dispatch('portalRealApp/approvePayableInvoice', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 201) {
            this.payableInvoicesData = response.data.data.page;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.showApproveBtn = false;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    rejectInvoice() {
      if (this.reject) {
        const payload = {
          ap_invoice_id: this.invoiceDetails.ap_invoice_id,
          rejected_reason: this.reject
        };
        this.loader = true;
        this.$store
          .dispatch('portalRealApp/rejectPayableInvoice', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 201) {
              this.showAlert = true;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.reject = null;
              this.showRejectInvoiceModal = false;
              this.showApproveBtn = false;
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
              this.showRejectInvoiceModal = false;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert('Please Enter Reject Reason');
      }
    },
    showHideRejectInvoiceModal(flag) {
      this.showRejectInvoiceModal = flag;
      if (!flag) {
        this.getPayableInvoiceList();
      }
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity.text = item.value_code;
        this.legalEntity.value = item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.APPROVAL_STATUS) {
        this.appStatus.text = item.value_meaning;
        this.appStatus.value = item.value_code;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SCHEDULE_STATUS_VSET
      ) {
        this.payStatus.text = item.value_meaning;
        this.payStatus.value = item.value_code;
      }
    },
    setOpenKmToken(token) {
      this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.downloadFiles(true);
    },
    downloadFiles(flag) {
      this.showDownloadModal = flag;
      this.getUploadedDocDtl();
    },
    showHideOpenKmModal(flag) {
      this.showOpenKmModal = flag;
    },
    getOpenKmAuthToken(item) {
      this.apInvoiceId = item.ap_invoice_id;
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmPartyPortalAuthenticate')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
            this.downloadFiles(true);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: 327,
        sub_menu_id: 0,
        record_id: this.apInvoiceId
      };
      this.downloadItems = [];
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page;
              this.downloadItems = docList.map(data => {
                data.file_size = this.formatBytes(data.file_size);
                return data;
              });
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    // advanceSearchModal() {
    //   this.isAdvanceSearchEnable = true;
    // },
    // showHidePayableInvoices(flag) {
    //   this.showPayableInvoicesModal = flag;
    // }
  }
};
